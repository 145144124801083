.App {
}

.App-header {
  display: flex;
  flex-wrap: wrap;
  color: white;
}
.App-header-top {
  display: flex;
  height: 7.5vh;
  flex: 1 0 100%;
  align-items: center;
  justify-content: center;
  background-color: #C0392B;
}
.App-header-bottom {
  display: flex;
  height: 7.5vh;
  flex: 1 0 100%;
  background-color: #FF7070;
}

.App-main {
  height: 75vh;
  padding-left: 10%;
  overflow-y: auto;
}

.step-counter {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: left;
  padding-bottom: 10px;
  padding-top: 10px;
}
.step-counter-span {
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  color: white;
  text-align: center;
  background-color: #B83729;
}
.step-counter-title {
  margin-left: 10px;
  font-size: 1rem;
  font-weight: bold;
}

input[type="radio"]:disabled {
  cursor: not-allowed;
}
input[type="checkbox"]:disabled {
  cursor: not-allowed;
}
.not-available {
  color: #a9a9a9;
}

.App-footer {
  display: flex;
  height: 10vh;
  flex: 1 0 100%;
  align-items: center;
  background-color: #c3c3c3;
}
.App-footer-item {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  text-align: center;
}
.App-footer a {
  text-decoration: none;
  color: #C0392B;
}

.material-icons {
  position: relative;
  top: 5px;
  font-size: 20px;
}
